<template>
  <div>
    <div class="row" style="justify-content: left; margin: 0; padding: 0">
        <div class="col-md-4 col-sm-4 bottom-space">
          <v-menu
            v-model="v_startDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pl-5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('header.start')"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="v_startDate = false">
            </v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-4 col-sm-4 bottom-space">
          <v-menu
            v-model="v_endDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('header.finish')"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="v_endDate = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-4 col-sm-4 bottom-space" style="margin-top: 15px;">
          <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="v_search"
            placeholder="Search"
            style="padding: 20px;  border-top-left-radius: 5px; border-bottom-left-radius: 5px;"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="getData()"
              style="
                border-radius: 0px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                line-height: 2.5;
                background-color: #1976d2 !important;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
      data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "desc", "type", "created_name","channel_type"],
      search: "",
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
    },
    loader1: false,
    v_search: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    v_startDate: null,
    v_endDate: null,
    v_startTime: "00:00",
    v_endTime: "23:59",
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
     
     this.loader1 = true;
     this.startTime = this.v_startTime;
     this.endTime = this.v_endTime;
     let formatStartDate = "";
     let formatEndDate = "";
     formatStartDate = this.startDate + "T" + this.startTime + ":00";
     formatEndDate = this.endDate + "T" + this.endTime + ":59";
     this.data.startDate = formatStartDate;
     this.data.endDate = formatEndDate;
     this.data.search = this.v_search;

     this.fetchListLogs(this.data).then(() => {
       let date = {
         startDate: formatStartDate,
         endDate: formatEndDate,
         search: this.v_search,
       };
       this.loader1 = false;
       this.$emit("child-checkbox", date);
     });
   },
    async deleteItemConfirm() {
      await this.fetchListLogs(this.params);
      this.dialogDelete = false;
    },
    async createDialog() {
      await this.fetchTreeMenu();
      this.fetchPopUp(true);
    },
    ...mapActions("$_menu", ["fetchTreeMenu"]),
    ...mapActions("$_activityLog", ["fetchPopUp", "fetchListLogs"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
